import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import type { SubmitHandler } from 'react-hook-form'
import Loading from 'components/section/form/components/loading'
import HeadSection from 'components/section/headSection'
import LeadExperienceSection from 'components/section/form/leadExperienceSection'
import ExperienceFormSection from 'components/section/form/experienceFormSection'
import ThanksSection from 'components/section/form/thanksSection'
import type { FormInputs } from 'types/form'
import encode from 'utils/encode'

export interface ExperienceTemplateProps {
  readonly headingText: string
  readonly userAgent: string
}

type ExperienceTemplatePropsClassName = ExperienceTemplateProps & {
  readonly className: string
}

function Component({ className, headingText, userAgent }: ExperienceTemplatePropsClassName) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isExperienceData, setIsExperienceData] = useState<boolean>(false)

  // エラーがなければ送信
  const onSubmit: SubmitHandler<FormInputs> = data => {
    setIsLoading(true)

    axios
      .post(
        '/',
        encode({
          'form-name': 'experience',
          formType: 'experience',
          userAgent: userAgent,
          ...data,
        }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      )
      .then(response => {
        if (typeof window !== 'undefined' && (window as any).dataLayer) {
          ;(window as any).dataLayer.push({ event: 'send-form-experience' })
        }
        setIsExperienceData(true)
        setIsLoading(false)
      })
      .catch(error => {
        // 失敗時の処理
        if (error) {
          setIsLoading(false)
        }
      })

    window.scroll({ top: 0 })
  }

  return (
    <div className={className}>
      <HeadSection
        pageHeadProps={{
          headingText: headingText,
          headingLevel: 'h1',
        }}
      />
      {!isExperienceData ? (
        <>
          <LeadExperienceSection />
          <ExperienceFormSection onSubmit={onSubmit} userAgent={userAgent} />
        </>
      ) : (
        <ThanksSection
          headElement={<>送信完了</>}
          bodyElement={
            <>
              <p>
                Fit NET STUDYへの体験申し込みを受け付けました。
                <br />
                3営業日以内に弊社よりメール・もしくはお電話にてご連絡差し上げます。
              </p>
              <p>【弊社からの連絡がない場合】</p>
              <p>お手数ですが、下記フリーコールまでお問い合わせください。</p>
              <p>
                0120-937-380
                <br />
                受付時間 9:00~21:00（日・祝を除く）
              </p>
            </>
          }
        />
      )}

      {isLoading && <Loading />}
    </div>
  )
}

const StyledComponent = styled(Component)<ExperienceTemplatePropsClassName>``

export const ExperienceTemplate: (props: ExperienceTemplateProps) => JSX.Element = StyledComponent

export default ExperienceTemplate
